import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Heading,
  Image,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { TWILIO_FROM } from '#constants';
import { theme } from '#services/chakra';
import { useUIStore } from '#store';

function displayTime(number) {
  if (number < 0 || number > 24) {
    return 'Invalid input: number must be between 0 and 24';
  } else if (number === 0) {
    return '12 AM';
  } else if (number < 12) {
    return number + ' AM';
  } else if (number === 12) {
    return '12 PM';
  } else {
    return number - 12 + ' PM';
  }
}

function CustomRangeSliderThumb({ range, index }) {
  return (
    <RangeSliderThumb
      index={index}
      _focusVisible={{ boxShadow: '0 0 0 2px green' }}
    >
      <Text
        position={'absolute'}
        top={0}
        mt={4}
        w="max-content"
        _peerActive={{ transform: 'scale(2)' }}
      >
        {displayTime(range[index])}
      </Text>
    </RangeSliderThumb>
  );
}

export default function TrackerNotification() {
  const {
    notificationPref,
    setNotificationPreference,
    updateUserPref,
    user,
    location,
  } = useUIStore((state) => ({
    notificationPref: state.trackerInput?.notificationPref,
    setNotificationPreference: state.setNotificationPreference,
    updateUserPref: state.updateUserPref,
    user: state.user,
    location: state.location,
  }));
  const defaultValue = [
    user?.preference?.callRange?.start ?? 8,
    user?.preference?.callRange?.end ?? 18,
  ];
  const [range, setRange] = useState(defaultValue);
  const updateUser = (val) => {
    updateUserPref({
      preference: {
        callRange: {
          start: val[0],
          end: val[1],
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    });
  };
  useEffect(() => {
    // Only set if we don't have one set.
    if (
      user?._id &&
      !user?.preference?.callRange?.start &&
      !user?.preference?.callRange?.end
    )
      updateUserPref({
        preference: {
          callRange: {
            start: 8,
            end: 18,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
      });
  }, []);

  const isCallEnabled = location.country === 'US';
  const isCallSelected = (notificationPref ?? []).indexOf('call') > -1;
  return (
    <Stack mt={4} mx={6} mb={2}>
      <CheckboxGroup
        colorScheme="brand"
        defaultValue={notificationPref}
        onChange={setNotificationPreference}
      >
        <Stack spacing={5} direction="row">
          <Text>Notification method: </Text>
          <Checkbox isChecked isReadOnly>
            Text
          </Checkbox>
          {isCallEnabled && (
            <Checkbox defaultChecked value="call">
              Call
            </Checkbox>
          )}
        </Stack>
      </CheckboxGroup>
      {isCallEnabled && (
        <Text fontStyle={'italic'} fontSize={'sm'}>
          The text/call will be from {TWILIO_FROM}
        </Text>
      )}
      {isCallEnabled && (
        <Flex
          visibility={isCallSelected ? 'visible' : 'hidden'}
          opacity={isCallSelected ? '1' : '0'}
          justify="space-between"
          transition={'opacity 0.5s ease'}
        >
          <Text flex={['0 0 auto', '0 1 20%']} mr={3}>
            Calling hours:{' '}
          </Text>
          <RangeSlider
            aria-label={['start-time', 'end-time']}
            defaultValue={defaultValue}
            min={0}
            max={24}
            step={1}
            onChangeEnd={updateUser}
            onChange={setRange}
            minStepsBetweenThumbs={1}
            flex="0 1 60%"
            colorScheme="brand"
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <CustomRangeSliderThumb index={0} range={range} />
            <CustomRangeSliderThumb index={1} range={range} />
          </RangeSlider>
        </Flex>
      )}
    </Stack>
  );
}
